import { Maybe } from '../../generated/graphql-types'

// Icons from https://thenounproject.com/
const icons = [
  'baking powder',
  'butter',
  'chicken',
  'eggs',
  'egg',
  'flour',
  'salad',
  'lemon',
  'lime',
  'milk',
  'liquid',
  'salt',
  'seeds',
  'strawberry',
  'sugar',
  'vanilla bean',
  'yeast',
  'yoghurt',
  'chocolate chips',
  'strawberry',
  'raspberry',
  'cream',
  'pasta',
  'garlic',
  'powder',
  'cheese',
  'pepperoni',
  'chilli',
  'onion',
  'bay leaf',
  'banana',
  'oats',
  'maple syrup',
  'honey',
] as const

type Icons = typeof icons[number]

const alternatives: { [key in Icons]?: string[] } = {
  yoghurt: ['yogurt'],
  pasta: ['macaroni'],
  'vanilla bean': ['vanilla extract'],
  strawberry: ['strawberries'],
  raspberry: ['raspberries'],
  liquid: ['oil', 'white wine vinegar', 'soy sauce'],
  powder: ['nutmeg', 'turmeric', 'ground almonds'],
  cheese: ['parmesan', 'cheddar'],
  'bay leaf': ['bay leaves'],
}

const fixName = (name: string) => {
  return name.replace(/\s/g, '-')
}

export const getIconFromName = (name: Maybe<string>) => {
  if (!name) {
    return 'unknown'
  }

  const search = name.toLowerCase()

  let icon = icons.find((icon) => {
    // Search names
    if (search.includes(icon)) {
      return true
    }

    const alternativeNames = alternatives[icon]

    // Search alternatives
    if (alternativeNames && alternativeNames.length) {
      return alternativeNames.find((alt) => {
        if (search.includes(alt)) {
          return true
        }
      })
    }
  })

  if (icon) {
    return fixName(icon)
  }

  return 'unknown'
}
